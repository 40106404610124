import { NextPageContext } from "next";
import loadNamespaces from "next-translate/loadNamespaces";
import useTranslation from "next-translate/useTranslation";

import {
    getLanguageFromAsPath,
    getLocaleFromAsPath,
    getRegionFromAsPath,
} from "utils/parseAsPath";
import { CountryCodeToVersion, CountryVersion, Language } from "constants/enum";
import { ErrorLayout } from "layouts/pages/ErrorLayout";
import { BaseLayout } from "layouts/BaseLayout";
import content from "content/error";

const Error = ({
    language = Language.SPAIN,
    version = CountryVersion.SPAIN,
    isMainPage,
    withHeader,
    withFooter,
    statusCode,
}: any) => {
    const { t } = useTranslation();
    const pageContent = content[version](t);

    return (
        <BaseLayout
            withHeader={withHeader}
            withFooter={withFooter}
            isErrorPage={Boolean(statusCode)}
            isMainPage={Boolean(isMainPage)}
            version={version}
            language={language}
            currentPage={"error"}
        >
            <ErrorLayout
                version={version}
                language={language}
                content={pageContent}
            />
        </BaseLayout>
    );
};

Error.getInitialProps = async ({
    query,
    pathname,
    asPath,
}: NextPageContext) => {
    const language = query.locale || (asPath && getLanguageFromAsPath(asPath));
    const locale = query.locale || (asPath && getLocaleFromAsPath(asPath));
    const version = asPath && CountryCodeToVersion[getRegionFromAsPath(asPath)];
    return {
        statusCode: "404",
        language,
        version,
        ...query,
        ...(await loadNamespaces({
            locale: locale as string,
            pathname,
        })),
    };
};

export default Error;
