import { NextRouter } from "next/router";

import {
    CountryCodeToLanguage,
    CountryCodeToVersion,
    CountryRegion,
    LOCALE_CODE,
} from "constants/enum";

export const getRegionFromAsPath = (
    asPath: NextRouter["asPath"]
): CountryRegion => {
    const region = asPath.split("/")[1];
    return region as CountryRegion;
};

export const getLanguageFromAsPath = (asPath: NextRouter["asPath"]) => {
    try {
        const region = getRegionFromAsPath(asPath);
        const language = CountryCodeToLanguage[region as CountryRegion];
        return language;
    } catch {}
};

export const getLocaleFromAsPath = (asPath: NextRouter["asPath"]) => {
    try {
        const region = getRegionFromAsPath(asPath);
        const version = CountryCodeToVersion[region as CountryRegion];
        const language = CountryCodeToLanguage[region as CountryRegion];

        return (LOCALE_CODE[version] as any)[language];
    } catch {}
};
